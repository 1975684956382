import { log } from 'common/log';

const sendMessageToClientToActivateAlert = async (
  channel: BroadcastChannel,
) => {
  // Prompt the user to update
  log.info('Service worker requires update.');
  channel.postMessage({ info: 'activate-update-software-popup' });
};

// Reacts if service worker is updated
const initializeOrUpdateServiceWorker = async (
  registration: ServiceWorkerRegistration,
) => {
  const channel = new BroadcastChannel('sw-messages');
  registration.addEventListener('updatefound', async () => {
    const newWorker = registration.installing as ServiceWorker;

    console.log('service worker registration status.', registration);
    console.log('service worker controller found an update.', newWorker);
    newWorker.addEventListener('statechange', async () => {
      // If the new service worker is installed
      console.log('new worker state in state change', newWorker.state);

      if (newWorker.state === 'installed') {
        // Check if there is an active service worker
        console.log(
          'controller and active',
          navigator.serviceWorker.controller,
          registration.active,
        );
        if (navigator.serviceWorker.controller && registration.active) {
          console.log('inside newly updated service worker activation');
          await sendMessageToClientToActivateAlert(channel);
        } else {
          log.info('Service worker registered.');
          // This is the first service worker being installed, no need to prompt
          log.info('Service worker installed for the first time.');
        }
      }
    });
  });
};

export const registerServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', async () => {
      await navigator.serviceWorker
        .register('/sw.js')
        .then((registration) => initializeOrUpdateServiceWorker(registration))
        .catch((err) => {
          window.alert(
            'Something Went Wrong! Offline capabilities will not be possible. Please contact the administrators.',
          );
          log.error('Service worker could not be registered', err);
        });
    });
  }
};
